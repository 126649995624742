import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  SAccountControl: () => import('../../components/SAccountControl.vue' /* webpackChunkName: "components/s-account-control" */).then(c => wrapFunctional(c.default || c)),
  SAccountControlCgu: () => import('../../components/SAccountControlCgu.vue' /* webpackChunkName: "components/s-account-control-cgu" */).then(c => wrapFunctional(c.default || c)),
  SAccountControlEmail: () => import('../../components/SAccountControlEmail.vue' /* webpackChunkName: "components/s-account-control-email" */).then(c => wrapFunctional(c.default || c)),
  SAccountControlPseudo: () => import('../../components/SAccountControlPseudo.vue' /* webpackChunkName: "components/s-account-control-pseudo" */).then(c => wrapFunctional(c.default || c)),
  SAccountControlRegister: () => import('../../components/SAccountControlRegister.vue' /* webpackChunkName: "components/s-account-control-register" */).then(c => wrapFunctional(c.default || c)),
  SAccountControlRs: () => import('../../components/SAccountControlRs.vue' /* webpackChunkName: "components/s-account-control-rs" */).then(c => wrapFunctional(c.default || c)),
  SAdSense: () => import('../../components/SAdSense.vue' /* webpackChunkName: "components/s-ad-sense" */).then(c => wrapFunctional(c.default || c)),
  SAddFormMap: () => import('../../components/SAddFormMap.vue' /* webpackChunkName: "components/s-add-form-map" */).then(c => wrapFunctional(c.default || c)),
  SCompteEmailForm: () => import('../../components/SCompteEmailForm.vue' /* webpackChunkName: "components/s-compte-email-form" */).then(c => wrapFunctional(c.default || c)),
  SCompteNotifForm: () => import('../../components/SCompteNotifForm.vue' /* webpackChunkName: "components/s-compte-notif-form" */).then(c => wrapFunctional(c.default || c)),
  SComptePasswordForm: () => import('../../components/SComptePasswordForm.vue' /* webpackChunkName: "components/s-compte-password-form" */).then(c => wrapFunctional(c.default || c)),
  SComptePseudoForm: () => import('../../components/SComptePseudoForm.vue' /* webpackChunkName: "components/s-compte-pseudo-form" */).then(c => wrapFunctional(c.default || c)),
  SCompteSettings: () => import('../../components/SCompteSettings.vue' /* webpackChunkName: "components/s-compte-settings" */).then(c => wrapFunctional(c.default || c)),
  SContextualList: () => import('../../components/SContextualList.vue' /* webpackChunkName: "components/s-contextual-list" */).then(c => wrapFunctional(c.default || c)),
  SDeleteContribution: () => import('../../components/SDeleteContribution.vue' /* webpackChunkName: "components/s-delete-contribution" */).then(c => wrapFunctional(c.default || c)),
  SDetailCarousel: () => import('../../components/SDetailCarousel.vue' /* webpackChunkName: "components/s-detail-carousel" */).then(c => wrapFunctional(c.default || c)),
  SDetailComment: () => import('../../components/SDetailComment.vue' /* webpackChunkName: "components/s-detail-comment" */).then(c => wrapFunctional(c.default || c)),
  SDetailCommentForm: () => import('../../components/SDetailCommentForm.vue' /* webpackChunkName: "components/s-detail-comment-form" */).then(c => wrapFunctional(c.default || c)),
  SDetailContributors: () => import('../../components/SDetailContributors.vue' /* webpackChunkName: "components/s-detail-contributors" */).then(c => wrapFunctional(c.default || c)),
  SDetailDescription: () => import('../../components/SDetailDescription.vue' /* webpackChunkName: "components/s-detail-description" */).then(c => wrapFunctional(c.default || c)),
  SDetailFacilities: () => import('../../components/SDetailFacilities.vue' /* webpackChunkName: "components/s-detail-facilities" */).then(c => wrapFunctional(c.default || c)),
  SDetailItem: () => import('../../components/SDetailItem.vue' /* webpackChunkName: "components/s-detail-item" */).then(c => wrapFunctional(c.default || c)),
  SDetailMap: () => import('../../components/SDetailMap.vue' /* webpackChunkName: "components/s-detail-map" */).then(c => wrapFunctional(c.default || c)),
  SDetailScheduleForm: () => import('../../components/SDetailScheduleForm.vue' /* webpackChunkName: "components/s-detail-schedule-form" */).then(c => wrapFunctional(c.default || c)),
  SDetailStreet: () => import('../../components/SDetailStreet.vue' /* webpackChunkName: "components/s-detail-street" */).then(c => wrapFunctional(c.default || c)),
  SDialogBox: () => import('../../components/SDialogBox.vue' /* webpackChunkName: "components/s-dialog-box" */).then(c => wrapFunctional(c.default || c)),
  SDialogBoxAlert: () => import('../../components/SDialogBoxAlert.vue' /* webpackChunkName: "components/s-dialog-box-alert" */).then(c => wrapFunctional(c.default || c)),
  SDialogBoxConfirm: () => import('../../components/SDialogBoxConfirm.vue' /* webpackChunkName: "components/s-dialog-box-confirm" */).then(c => wrapFunctional(c.default || c)),
  SDialogBoxFullscreen: () => import('../../components/SDialogBoxFullscreen.vue' /* webpackChunkName: "components/s-dialog-box-fullscreen" */).then(c => wrapFunctional(c.default || c)),
  SDialogBoxPicture: () => import('../../components/SDialogBoxPicture.vue' /* webpackChunkName: "components/s-dialog-box-picture" */).then(c => wrapFunctional(c.default || c)),
  SDialogBoxSimple: () => import('../../components/SDialogBoxSimple.vue' /* webpackChunkName: "components/s-dialog-box-simple" */).then(c => wrapFunctional(c.default || c)),
  SDynamicScroller: () => import('../../components/SDynamicScroller.vue' /* webpackChunkName: "components/s-dynamic-scroller" */).then(c => wrapFunctional(c.default || c)),
  SDynamicScrollerItem: () => import('../../components/SDynamicScrollerItem.vue' /* webpackChunkName: "components/s-dynamic-scroller-item" */).then(c => wrapFunctional(c.default || c)),
  SErrorState: () => import('../../components/SErrorState.vue' /* webpackChunkName: "components/s-error-state" */).then(c => wrapFunctional(c.default || c)),
  SFormLogin: () => import('../../components/SFormLogin.vue' /* webpackChunkName: "components/s-form-login" */).then(c => wrapFunctional(c.default || c)),
  SGeoMapbox: () => import('../../components/SGeoMapbox.vue' /* webpackChunkName: "components/s-geo-mapbox" */).then(c => wrapFunctional(c.default || c)),
  SImageCropper: () => import('../../components/SImageCropper.vue' /* webpackChunkName: "components/s-image-cropper" */).then(c => wrapFunctional(c.default || c)),
  SImageLoader: () => import('../../components/SImageLoader.vue' /* webpackChunkName: "components/s-image-loader" */).then(c => wrapFunctional(c.default || c)),
  SListItem: () => import('../../components/SListItem.vue' /* webpackChunkName: "components/s-list-item" */).then(c => wrapFunctional(c.default || c)),
  SLoadingState: () => import('../../components/SLoadingState.vue' /* webpackChunkName: "components/s-loading-state" */).then(c => wrapFunctional(c.default || c)),
  SLocInput: () => import('../../components/SLocInput.vue' /* webpackChunkName: "components/s-loc-input" */).then(c => wrapFunctional(c.default || c)),
  SLocalisationWrapper: () => import('../../components/SLocalisationWrapper.vue' /* webpackChunkName: "components/s-localisation-wrapper" */).then(c => wrapFunctional(c.default || c)),
  SMicrodatas: () => import('../../components/SMicrodatas.vue' /* webpackChunkName: "components/s-microdatas" */).then(c => wrapFunctional(c.default || c)),
  SNoResult: () => import('../../components/SNoResult.vue' /* webpackChunkName: "components/s-no-result" */).then(c => wrapFunctional(c.default || c)),
  SPassword: () => import('../../components/SPassword.vue' /* webpackChunkName: "components/s-password" */).then(c => wrapFunctional(c.default || c)),
  SPlaygroundMicrodatas: () => import('../../components/SPlaygroundMicrodatas.vue' /* webpackChunkName: "components/s-playground-microdatas" */).then(c => wrapFunctional(c.default || c)),
  SReadMore: () => import('../../components/SReadMore.vue' /* webpackChunkName: "components/s-read-more" */).then(c => wrapFunctional(c.default || c)),
  SResultListView: () => import('../../components/SResultListView.vue' /* webpackChunkName: "components/s-result-list-view" */).then(c => wrapFunctional(c.default || c)),
  SResultMap: () => import('../../components/SResultMap.vue' /* webpackChunkName: "components/s-result-map" */).then(c => wrapFunctional(c.default || c)),
  SResultTitle: () => import('../../components/SResultTitle.vue' /* webpackChunkName: "components/s-result-title" */).then(c => wrapFunctional(c.default || c)),
  SSchedule: () => import('../../components/SSchedule.vue' /* webpackChunkName: "components/s-schedule" */).then(c => wrapFunctional(c.default || c)),
  SScheduleGlobal: () => import('../../components/SScheduleGlobal.vue' /* webpackChunkName: "components/s-schedule-global" */).then(c => wrapFunctional(c.default || c)),
  SScheduleMonths: () => import('../../components/SScheduleMonths.vue' /* webpackChunkName: "components/s-schedule-months" */).then(c => wrapFunctional(c.default || c)),
  SSchedulePeriods: () => import('../../components/SSchedulePeriods.vue' /* webpackChunkName: "components/s-schedule-periods" */).then(c => wrapFunctional(c.default || c)),
  SSchedulePreview: () => import('../../components/SSchedulePreview.vue' /* webpackChunkName: "components/s-schedule-preview" */).then(c => wrapFunctional(c.default || c)),
  SScheduleWeek: () => import('../../components/SScheduleWeek.vue' /* webpackChunkName: "components/s-schedule-week" */).then(c => wrapFunctional(c.default || c)),
  SScrollBox: () => import('../../components/SScrollBox.vue' /* webpackChunkName: "components/s-scroll-box" */).then(c => wrapFunctional(c.default || c)),
  SSearchForm: () => import('../../components/SSearchForm.vue' /* webpackChunkName: "components/s-search-form" */).then(c => wrapFunctional(c.default || c)),
  SSideMenu: () => import('../../components/SSideMenu.vue' /* webpackChunkName: "components/s-side-menu" */).then(c => wrapFunctional(c.default || c)),
  SSnackBar: () => import('../../components/SSnackBar.vue' /* webpackChunkName: "components/s-snack-bar" */).then(c => wrapFunctional(c.default || c)),
  SSsrLink: () => import('../../components/SSsrLink.vue' /* webpackChunkName: "components/s-ssr-link" */).then(c => wrapFunctional(c.default || c)),
  SSwiper: () => import('../../components/SSwiper.vue' /* webpackChunkName: "components/s-swiper" */).then(c => wrapFunctional(c.default || c)),
  SSwiperElement: () => import('../../components/SSwiperElement.vue' /* webpackChunkName: "components/s-swiper-element" */).then(c => wrapFunctional(c.default || c)),
  STest: () => import('../../components/STest.vue' /* webpackChunkName: "components/s-test" */).then(c => wrapFunctional(c.default || c)),
  SToolbar: () => import('../../components/SToolbar.vue' /* webpackChunkName: "components/s-toolbar" */).then(c => wrapFunctional(c.default || c)),
  STooltip: () => import('../../components/STooltip.vue' /* webpackChunkName: "components/s-tooltip" */).then(c => wrapFunctional(c.default || c)),
  SUploadImage: () => import('../../components/SUploadImage.vue' /* webpackChunkName: "components/s-upload-image" */).then(c => wrapFunctional(c.default || c)),
  SUploadMultiple: () => import('../../components/SUploadMultiple.vue' /* webpackChunkName: "components/s-upload-multiple" */).then(c => wrapFunctional(c.default || c)),
  SUploadMultipleItem: () => import('../../components/SUploadMultipleItem.vue' /* webpackChunkName: "components/s-upload-multiple-item" */).then(c => wrapFunctional(c.default || c)),
  SZoomImage: () => import('../../components/SZoomImage.vue' /* webpackChunkName: "components/s-zoom-image" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
