//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'SToolbar',
  data() {
    return {
      isActive: false,
      toggleViewMode: true,
      isDetailList: false,
    };
  },
  computed: {
    ...mapGetters({
      isSideToggled: 'menu/isSideToggled',
      isMedium: 'screen/isMedium',
      isLandscape: 'screen/isLandscape',
      getDetailFromMap: 'preferences/getDetailFromMap',
      getViewMode: 'preferences/getViewMode',
      getBackArrow: 'transferData/getBackArrow',
      getResults: 'transferData/getResults',
      getContext: 'transferData/getContext',
    }),
    /** @returns {Boolean} */
    displayLarge() {
      return this.isMedium && this.isLandscape;
    },
  },
  watch: {
    getViewMode() {
      this.toggleViewMode = this.getViewMode === 'list';
    },
  },
  mounted() {
    this.isDetailList = this.$route.name === 'detail-list' || this.$route.name === 'detail';
  },
  methods: {
    ...mapActions({
      toggleSide: 'menu/toggleSide',
      updateViewMode: 'preferences/updateViewMode',
    }),
    clickOnToggle() {
      this.toggleSide();
    },
    changeViewMode(view) {
      this.updateViewMode(view);
    },
    // methode2
    changeMode(view) {
      this.updateViewMode(view);
      if (this.toggleViewMode) {
        this.changeViewMode('list');
      } else {
        this.changeViewMode('map');
      }
    },
  },
};

