//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';

// Libraries
import * as storage from '../libraries/localStorage';
import { splitAndRessort } from '../libraries/utils';
import { getCookie, setCookie } from '../libraries/cookies';

import initNavPwa from '../libraries/navPwaHelpers';

// Mixins
import errorsMixin from '../mixins/error-handler';
import metaMixin from '../mixins/meta';

// Api
import * as apiAuthentication from '../api/public/authentication';
import * as apiProfile from '../api/private/profile';

export default {
  name: 'App',
  mixins: [errorsMixin, metaMixin],
  data() {
    return {
      hasVerticalScroll: false,
      unsubscribeFirebaseTokenObserver: () => {},
      unsubscribeFirebaseAuthObserver: () => {},
      // dialog box compte bloqué
      dialogUserBlockedOptions: {
        maxWidth: 600,
      },
      dialogUserBlockedButtons: {
        closeText: 'Fermer',
      },
      dialogUserBlockedTitle: 'Connexion impossible',
      // dialog box "À propos"
      btnAbout: false,
      dialogAboutLabel: 'À propos - Présentation du site adj.info',
      dialogAboutOptions: {
        maxWidth: '33%',
        maxHeight: '90%',
        scrollable: true,
        retainFocus: false,
      },
      dialogAboutButtons: {
        closeText: 'Fermer',
      },
      // dialog box Réactivation de compte
      dialogRestoreAccountTitle: 'Compte en cours de suppression',
      dialogRestoreAccountOptions: {
        maxWidth: 520,
        contentClass: 'dialog-restore-account',
      },
      dialogRestoreAccountButtons: {
        closeText: 'Me déconnecter',
        confirmText: 'Réactiver mon compte',
      },
      // dialog box explications push
      dialogPushInformationTitle: 'Notifications',
      dialogPushInformationOptions: {
        maxWidth: '80%',
        width: '600',
      },
      dialogPushInformationButtons: {
        closeText: 'Je refuse',
        confirmText: "J'accepte",
      },
      // dialog box refus de permissions
      dialogPermissionsTitle: 'Notifications bloquées',
      dialogPermissionsOptions: {
        maxWidth: '80%',
        width: '600',
      },
      dialogPermissionsButtons: {
        closeText: 'Fermer',
        confirmText: "J'ai compris",
      },
      // dialog box erreurs HTTP (401, 500)
      dialogErrorHttpOptions: {
        maxWidth: 520,
      },
      dialogErrorHttpButtons: {
        closeText: 'Fermer',
      },
      // dialog box erreur token/push
      dialogTokenTitle: 'Notifications',
      dialogTokenOptions: {
        maxWidth: '80%',
        width: '600',
      },
      dialogTokenButton: {
        closeText: 'Fermer',
      },
      // snackbar
      snackbarPreferencesError: {
        options: {
          color: 'error',
        },
        content: 'Une erreur est survenue',
      },
    };
  },
  computed: {
    ...mapGetters({
      // authToken
      awaitAuth: 'authToken/awaitAuth',
      emailToken: 'authToken/emailToken',
      isAuth: 'authToken/isAuth',
      // firebase
      access: 'firebase/access',
      confirm: 'firebase/confirm',
      getFirebaseUserUid: 'firebase/userUid',
      getUserNotMatch: 'firebase/getUserNotMatch',
      isPending: 'firebase/isPending',
      // menu
      getSideMenuEnabled: 'menu/getSideMenuEnabled',
      getToolbarEnabled: 'menu/getToolbarEnabled',
      isSideToggled: 'menu/isSideToggled',
      // preferences
      getDarkMode: 'preferences/getDarkMode',
      getDialogPending: 'preferences/getDialogPending',
      getDialogPermissions: 'preferences/getDialogPermissions',
      getDialogPushInformation: 'preferences/getDialogPushInformation',
      getGlobalError: 'preferences/getGlobalError',
      getUser: 'preferences/getUser',
      getUserPrefs: 'preferences/getUserPrefs',
      isDialogAboutOpen: 'preferences/isDialogAboutOpen',
      isDialogAboutViewed: 'preferences/isDialogAboutViewed',
      isPwa: 'preferences/isPwa',
      userSecured: 'preferences/userSecured',
      // screen
      getHasHover: 'screen/getHasHover',
      getIsMobile: 'screen/getIsMobile',
      getToolbarSize: 'screen/getToolbarSize',
      isMedium: 'screen/isMedium',
      isXSmall: 'screen/isXSmall',
      // snackbar
      presetSnackbar: 'snackbar/isPreset',
      showSnackbar: 'snackbar/isVisible',
      snackbarButton: 'snackbar/getButton',
      snackbarContent: 'snackbar/getContent',
      snackbarOptions: 'snackbar/getOptions',
      // syncTab
      getUserControl: 'syncTab/getUserControl',
      dialogRestoreAccount: 'syncTab/dialogRestoreAccount',
      // transferData
      getKeepAlive: 'transferData/getKeepAlive',
    }),
    isPublicOnly() {
      return this.$route.meta.publicOnly;
    },
    isPrivate() {
      return this.$route.meta.private;
    },
    isPartial() {
      return this.$route.meta.partial;
    },
    isLogginIn() {
      return this.isPending && (this.isPublicOnly || this.isPartial);
    },
    dialogPending: {
      get() {
        return !!this.getDialogPending;
      },
      set() {},
    },
    dialogAbout: {
      get() {
        return this.isDialogAboutOpen;
      },
      set() {},
    },
    hasUserControl() {
      return this.getUserControl.active;
    },
    dialogRestoreAccountOpen: {
      get() {
        return this.dialogRestoreAccount.open;
      },
      set() {},
    },
    dialogRestoreAccountLoading() {
      return this.dialogRestoreAccount.loading;
    },
    dialogPermissions: {
      get() {
        return !!this.getDialogPermissions;
      },
      set() {},
    },
    dialogPushInformation: {
      get() {
        return !!this.getDialogPushInformation;
      },
      set() {},
    },
    dialogErrorToken: {
      get() {
        return !!this.errors$.getAll('token');
      },
      set() {},
    },
    hasErrorLogout: {
      get() {
        return this.errors$.getCode('http', 401) === 'logout';
      },
      set() {},
    },
    hasError401: {
      get() {
        return !!this.errors$.get('http', '401');
      },
      set() {},
    },
    hasError500: {
      get() {
        return !!this.errors$.get('http', '500');
      },
      set() {},
    },
    hasUserDeletedError() {
      return this.errors$.getCode('http', '403') === 'user_deleted';
    },
    hasUserBlockedError: {
      get() {
        return this.errors$.getCode('http', '403') === 'user_blocked';
      },
      set() {},
    },
    cachePage() {
      if (this.getKeepAlive) {
        if (this.$route.name === 'FavPlaygrounds') {
          return ['FavPlaygrounds'];
        }
        if (this.$route.name === 'PrivateComments') {
          return ['PrivateComments'];
        }
        if (this.$route.name === 'Contributions') {
          return ['Contributions'];
        }
        if (
          this.$route.name === 'list-city'
          || this.$route.name === 'detail-list'
        ) {
          return [
            'List',
            'FavPlaygrounds',
            'PrivateComments',
            'Contributions',
            'Detail',
          ];
        }
      }
      return [];
    },
    noScrollPage() {
      return (
        this.$route.name === 'list-admin'
        || this.$route.name === 'list-city-admin'
        || this.$route.name === 'list-city'
        || this.$route.name === 'detail-list'
        || this.$route.name === 'detail'
        || this.$route.name === 'comments'
        || this.$route.name === 'FavPlaygrounds'
        || this.$route.name === 'PrivateComments'
        || this.$route.name === 'Contributions'
        || this.$route.name === 'addForm'
        || this.$route.name === '404'
      );
    },
    nuxtChildKey() {
      if (this.$route.name === 'detail-list') {
        return 'detail-list';
      }
      if (this.$route.name === 'list-city') {
        return 'list-city';
      }
      return null;
    },
    appClass() {
      return {
        'toolbar-enabled': this.getToolbarEnabled,
        'has-vertical-scroll': this.hasVerticalScroll,
        'has-hover': this.getHasHover,
        'no-hover': !this.getHasHover,
      };
    },
    mainContainerClass() {
      return this.isSideToggled ? 'side-on' : 'side-off';
    },
    userAuthState() {
      return `${this.isAuth}-${this.isPending}-${this.userSecured}-${this.hasUserControl}`;
    },
  },
  watch: {
    userAuthState() {
      // wait firebase end
      if (this.isPending) return;

      // disconnected
      if (!this.isAuth) {
        if (!this.isDialogAboutViewed && this.$route.name === 'home') {
          this.openDialogAbout();
        }
        if (this.$route.meta.private) {
          if (this.hasError401) {
            this.$newRouter.replace({
              name: 'Login',
              query: { redirect: this.$route.fullPath },
            });
          } else {
            this.$newRouter.replace({ name: 'Login' });
          }
        }
        // connected
      } else if (!this.userSecured && (this.isPublicOnly || this.isPrivate)) {
        this.$newRouter.replace({ name: 'Login' });
        // secured
      } else if (
        this.userSecured
        && !this.hasUserControl
        && (this.isPublicOnly || this.isPartial)
      ) {
        let redirect = this.$route.query.redirect ?? null;

        if (this.getUser.reactive) {
          // Avoid welcomeBack redirect to welcomeBack
          if (redirect) {
            const toRoute = {
              ...this.$router.resolve({ path: redirect }).resolved,
              matched: null,
            };
            redirect = toRoute.name !== 'WelcomeBack' ? redirect : null;
          }
          this.$newRouter.replace({
            name: 'WelcomeBack',
            query: redirect ? { redirect } : {},
          });
        } else if (redirect) {
          this.$newRouter.replace({ path: redirect });
        } else {
          this.$newRouter.replace({ name: 'Account' });
        }
      }
    },
    getGlobalError(data) {
      if (data && data.env !== 'login') {
        this.errors$.addError(data);
        this.setGlobalError(null);
      }
    },
    isLogginIn: {
      immediate: true,
      handler() {
        if (this.isLogginIn && !this.getDialogPending) {
          this.setDialogPending(true);
        }
      },
    },
    isPending() {
      if (!this.isPending) {
        this.setDialogPending(null);
      }
    },
    isAuth(newValue, oldValue) {
      if (!newValue && oldValue !== null) {
        apiAuthentication.logout(this);
        this.setUser({});
        this.resetUserControl();
        this.resetAuth();
        this.setEmailToken(null);
        this.setUserNotMatch(false);
        this.setConfirm(false);
        this.updateTutoBlur(null);
        // Empty favorites from store on logout
        this.resetListFavorites();
      } else if (this.isAuth) {
        // Hide the "About" button if the user is authenticated
        this.setDialogAboutViewed(true);
      }
    },
    isDialogAboutViewed: {
      handler() {
        if (process.client) {
          this.btnAbout = !this.isDialogAboutViewed;
        }
      },
      immediate: true,
    },
    awaitAuth() {
      if (!this.awaitAuth) {
        this.firebaseTokenListener();
      }
    },
    $route() {
      this.setRouteLoading(false);
      this.$refs.scroller?.scrollToPosition(0);
    },
    cachePage() {
      this.$nextTick(() => {
        if (!this.getKeepAlive && this.cachePage.length === 0) {
          this.updateKeepAlive(true);
        }
      });
    },
    getDarkMode() {
      this.$vuetify.theme.dark = this.getDarkMode;
    },
  },
  beforeCreate() {
    /* window.googletag = window.googletag || { cmd: [] };

    window.googletag.cmd.push(() => {
      window.googletag.pubads().enableSingleRequest();
      window.googletag.enableServices();
    }); */
  },
  created() {
    this.initAfterEach();

    if (process.client) {
      // Init
      window.addEventListener('resize', this.getWindowSize);
      this.getWindowSize();

      // init darkmode
      this.initDarkMode();

      // detect device
      this.initDevice();

      // Check for firebase init
      this.unsubscribeFirebaseAuthObserver = this.$firebase.onAuthStateChanged(
        (user) => {
          if (!user) {
            this.resetFirebase();
            // Reset auth, except if it's a 'user_not_match' error and not partial
            if (!this.getUserNotMatch && this.userSecured) {
              this.setIsAuth(false);
              if (this.dialogRestoreAccountOpen) {
                this.closeRestoreAccount();
              }
            }
          }
        },
      );

      storage.remove('currentUser');
      this.setFirebaseUserUid(null);

      // Email token saved in cookies
      const emailTokenCookie = getCookie('emailToken');

      // Save the token in the store
      // Triggers user authentication in default.vue
      if (this.$route.name === 'Unsubscribe' && this.$route.params.token) {
        this.setEmailToken(this.$route.params.token);
      } else if (this.$route.query.token) {
        this.setEmailToken(this.$route.query.token);
      } else if (emailTokenCookie) {
        this.setEmailToken(emailTokenCookie);
      }

      this.setAwaitAuth(this.$route.query.await || false);
    }
  },
  mounted() {
    if (!this.awaitAuth) {
      this.firebaseTokenListener();
    }
  },
  // Inutile ?
  // https://app.bugsnag.com/sopheos/aire-de-jeux/errors/63133dfb139cbd0008f828aa
  // updated() {
  //   this.$nextTick(() => {
  //     if (this.$route.hash) {
  //       const el = document.querySelector(this.$route.hash);
  //       el.scrollIntoView();
  //     }
  //   });
  // },
  beforeDestroy() {
    this.unsubscribeFirebaseTokenObserver();
    this.unsubscribeFirebaseAuthObserver();
    window.removeEventListener('resize', this.getWindowSize);
    const darkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    try {
      // All browsers
      darkMediaQuery.removeEventListener('change', this.setDarkMode);
    } catch (e1) {
      try {
        // Safari - removeListener (deprecated)
        darkMediaQuery.removeListener(() => this.setDarkMode);
      } catch (e2) {
        this.$bugsnag.notify(e2);
      }
    }
  },
  methods: {
    ...mapActions({
      // authToken
      resetAuth: 'authToken/resetAuth',
      setAwaitAuth: 'authToken/setAwaitAuth',
      setEmailToken: 'authToken/setEmailToken',
      setIsAuth: 'authToken/setIsAuth',
      // dialogs
      removeLastCloseDialogFn: 'dialogs/removeLastCloseFn',
      // firebase
      resetFirebase: 'firebase/reset',
      setConfirm: 'firebase/setConfirm',
      setFirebasePending: 'firebase/setPending',
      setFirebaseUser: 'firebase/setUser',
      setFirebaseUserUid: 'firebase/setUserUid',
      setUserNotMatch: 'firebase/setUserNotMatch',
      // menu
      enableToolbar: 'menu/enableToolbar',
      // preferences
      askPushPermission: 'preferences/askPushPermission',
      setDialogAboutOpen: 'preferences/setDialogAboutOpen',
      setDialogAboutViewed: 'preferences/setDialogAboutViewed',
      setDialogPending: 'preferences/setDialogPending',
      setDialogPermissions: 'preferences/setDialogPermissions',
      setDialogPushInformation: 'preferences/setDialogPushInformation',
      setGlobalError: 'preferences/setGlobalError',
      setPwa: 'preferences/setPwa',
      setUser: 'preferences/setUser',
      updateDarkMode: 'preferences/updateDarkMode',
      updateUserPrefs: 'preferences/updateUserPrefs',
      updateTutoBlur: 'preferences/updateTutoBlur',
      // router
      setRouteLoading: 'router/setRouteLoading',
      // screen
      updateHasHover: 'screen/updateHasHover',
      updateHeight: 'screen/updateWindowHeight',
      updateIsMobile: 'screen/updateIsMobile',
      updateWidth: 'screen/updateWindowWidth',
      // snackbar
      initSnackbar: 'snackbar/initSnackbar',
      // syncTab
      resetUserControl: 'syncTab/resetUserControl',
      setUserControlActive: 'syncTab/setUserControlActive',
      setDialogRestoreAccountLoading: 'syncTab/setDialogRestoreAccountLoading',
      setDialogRestoreAccountOpen: 'syncTab/setDialogRestoreAccountOpen',
      // transferData
      resetListFavorites: 'transferData/resetListFavorites',
      updateKeepAlive: 'transferData/updateKeepAlive',
      updateBackArrow: 'transferData/updateBackArrow',
    }),
    firebaseTokenListener() {
      this.unsubscribeFirebaseTokenObserver = this.$firebase.onIdTokenChanged(
        async (user) => {
          this.setFirebasePending(true);

          // User connected to firebase
          if (user) {
            // Not the same user : authentication
            const userUid = splitAndRessort(user.uid, 3);
            if (
              storage.get('currentUser') !== userUid
              || this.getFirebaseUserUid !== userUid
            ) {
              storage.set('currentUser', userUid);
              this.setFirebaseUserUid(userUid);

              let email = user.email;

              if (!email) {
                const providerWithEmail = user.providerData.find(
                  (provider) => provider.email,
                );
                if (providerWithEmail) {
                  email = providerWithEmail.email;
                }
              }

              user = {
                displayName: user.displayName,
                email,
              };

              // Firebase authentication
              await this.$firebase
                .getIdToken(true)
                .then((access) => {
                  this.setFirebaseUser({ user, access });
                })
                .catch(() => {
                  this.setFirebasePending(false);
                  this.resetFirebase();
                });

              if (this.access) {
                // Server authentication - type firebase
                await apiAuthentication
                  .login(
                    {
                      type: 'firebase',
                      token: this.access,
                      confirm: this.confirm,
                    },
                    this,
                  )
                  .then((res) => {
                    // Define the user who is authenticated from firebase
                    // only if no emailToken is stored or if it's not an account confirmation
                    if (!this.emailToken || this.confirm) {
                      this.defineUser(res);
                    }
                  })
                  .catch((error) => {
                    if (error?.response?.data?.error) {
                      // If it's a 'user_not_match' error: logout from firebase
                      if (
                        error.response.status === 400
                        && error.response.data.error === 'user_not_match'
                      ) {
                        // Set the 'user_not_match' error
                        this.setUserNotMatch(true);
                      } else if (error.response.data.env === 'login') {
                        const data = {
                          env: error.response.data.env,
                          fields: error.response.data.errors,
                          extra: error.response.data.extra,
                        };
                        this.setGlobalError(data);
                      } else if (
                        !(this.hasUserDeletedError || this.hasUserBlockedError)
                      ) {
                        const field = error.response.data.error === 'token_invalid'
                          ? 'token'
                          : 'form';
                        const data = {
                          env: 'login',
                          fields: { [field]: error.response.data.error },
                        };
                        this.setGlobalError(data);
                      }
                    }

                    // Logout, except if it's a 'user_deleted' 403 error
                    // and it's not a partial authentication
                    if (this.hasUserDeletedError) {
                      if (!this.emailToken) {
                        this.setDialogRestoreAccountOpen(true);
                      }
                    } else {
                      this.$firebase.signOut();
                    }
                    this.setFirebasePending(false);
                  });
              } else {
                // User should always have an access token. If not, logout
                this.setFirebasePending(false);
                this.resetFirebase();
              }
            }

            // Email Token and not an account confirmation
            if (this.emailToken && !this.confirm) {
              // Server authentication - type email
              apiAuthentication
                .login({ type: 'email', token: this.emailToken }, this)
                .then((res) => {
                  // It is not the same user
                  if (!res.is_secured) {
                    // Logout from firebase
                    this.$firebase.signOut();
                  }
                  // Define the user
                  this.defineUser(res);
                })
                .catch(() => {
                  // Logout
                  this.$firebase.signOut();
                  this.setFirebasePending(false);
                  if (this.hasUserDeletedError) {
                    this.closeRestoreAccount();
                  }
                  this.setEmailToken(null);
                });
            } else {
              this.setFirebasePending(false);
            }
          } else if (this.emailToken && !this.isAuth) {
            // Email Token and user not authenticated
            // Server authentication - type email
            apiAuthentication
              .login({ type: 'email', token: this.emailToken }, this)
              .then((res) => {
                // Define the user
                this.defineUser(res);
              })
              .catch(() => {
                // Logout
                this.setFirebasePending(false);
                this.setEmailToken(null);
              });
          } else {
            // Logout from firebase
            storage.remove('currentUser');
            this.setFirebaseUserUid(null);
            this.setFirebasePending(false);
          }
        },
      );
    },
    initAfterEach() {
      this.initMenu(this.$route);
      this.$router.afterEach((to) => {
        if (!this.isDialogAboutViewed && to.name === 'home') {
          this.openDialogAbout();
        }
        this.initMenu(to);
        this.initScroll();
      });
    },
    initMenu(route) {
      const isTopLevelPage = route.meta.topLevel;
      this.updateBackArrow(!isTopLevelPage || !!route.query.redirect);
      if (!route.meta.handleToolbar) {
        this.enableToolbar();
      }
    },
    initScroll() {
      const globalContainer = document.querySelector('.js-global-container');
      globalContainer.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
    noMoreControl() {
      this.setUserControlActive(false);
      this.initPreferences();
    },
    initDevice() {
      // UserAgentData / fallback UserAgentString si pas supporté
      if (navigator.userAgentData) {
        this.updateIsMobile(navigator.userAgentData.mobile);
      } else {
        this.updateIsMobile(
          /(android|iphone|ipad|ipod|windows phone)/i.test(navigator.userAgent),
        );
      }

      // détecter IOS => pas encore supporté 10/06/21
      //   navigator.userAgentData.getHighEntropyValues(['platform']).then((data) => {
      //   if (data.platform === 'iOS') {
      //     document.documentElement.classList.add('is-apple');
      //   }
      // });

      // ! TEST - add class to html for mac/iphone/ipad etc.
      if (
        (/iPad|iPhone|iPod/.test(navigator.userAgent)
          || navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i))
        && !window.MSStream
      ) {
        document.documentElement.classList.add('is-apple');
      }

      if (/firefox|fxios|/.test(navigator.userAgent)) {
        document.documentElement.classList.add('is-firefox');
      }

      const displayModes = ['fullscreen', 'standalone', 'minimal-ui'];
      displayModes.forEach((displayMode) => {
        if (window.matchMedia(`(display-mode: ${displayMode})`).matches) {
          this.setPwa(true);
        }
      });

      if (this.isPwa) {
        initNavPwa();
      }
    },
    defineUser(res) {
      this.setUserControlActive(res.control);
      this.setUser({
        name: res.name,
        avatar: res.avatar,
        placeholder: res.placeholder,
        alias: res.alias,
        email: res.email,
        contributions: res.contributions,
        contributionsNextLvl: res.contributions_next_lvl,
        lvl: res.lvl,
        reactive: res.reactive,
        providers: res.providers,
        isSecured: res.is_secured,
        createdAt: res.created_at,
        hash: res.hash,
      });
      if (!res.control) {
        this.initPreferences();
      }
      this.setIsAuth(true);
      this.setFirebasePending(false);

      // Display the notification information dialog box
      if ('Notification' in window) {
        if (
          res.push
          && res.is_secured
          && !getCookie(`push_dialog_${this.getUser.hash}`)
          && !getCookie(`push_denied_${this.getUser.hash}`)
        ) {
          if (Notification.permission === 'granted') {
            this.askPushPermission(true);
          } else {
            setTimeout(() => {
              this.setDialogPushInformation(true);
            }, 250);
          }
        }
      }
    },
    initPreferences() {
      if (this.userSecured) {
        apiProfile
          .preferences(this)
          .then((prefs) => {
            const syncTutoswiper = this.getUserPrefs.tutoswiper.filter(
              (x) => !prefs.user.tutoswiper.includes(x),
            );
            if (syncTutoswiper.length) {
              apiProfile.updatePreference({ tutoswiper: syncTutoswiper }, this);
              prefs.user.tutoswiper.push(...syncTutoswiper);
            }
            this.updateUserPrefs(prefs);
            if (prefs.user.darkmode !== null) {
              this.setDarkMode(null, prefs.user.darkmode);
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 404) {
              this.initSnackbar(this.snackbarPreferencesError);
            }
          });
      }
    },
    initDarkMode() {
      const darkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      setTimeout(() => {
        if (darkMediaQuery.matches) {
          this.setDarkMode(darkMediaQuery);
        } else {
          this.setDarkMode(null, false);
        }
      }, 0);

      try {
        // All browsers
        darkMediaQuery.addEventListener('change', this.setDarkMode);
      } catch (e1) {
        try {
          // Safari - addListener (deprecated)
          darkMediaQuery.addListener(() => this.setDarkMode);
        } catch (e2) {
          this.$bugsnag.notify(e2);
        }
      }
    },
    setDarkMode(darkmode, userPrefs) {
      if (darkmode) {
        this.updateDarkMode(darkmode.matches);
      } else if (userPrefs !== undefined) {
        this.updateDarkMode(userPrefs);
      }
    },
    // Calcul de Size
    getWindowSize() {
      const vh = window.innerHeight * 0.01;
      const vw = window.innerWidth * 0.01;

      document.documentElement.style.setProperty('--vh', `${vh}px`);
      document.documentElement.style.setProperty('--vw', `${vw}px`);

      this.updateWidth(window.innerWidth);
      this.updateHeight(window.innerHeight);

      this.updateHasHover(window.matchMedia('(hover: hover)').matches);
    },
    // fin Calcul de Size
    openDialogAbout() {
      this.setDialogAboutOpen(true);
      this.setDialogAboutViewed(true);
    },
    dismissDialogAbout() {
      this.setDialogAboutOpen(false);
    },
    logout() {
      this.$firebase.signOut();
      this.closeRestoreAccount();
      this.setEmailToken(null);
    },
    closeRestoreAccount() {
      this.resetError403();
      this.setDialogRestoreAccountOpen(false);
      this.errors$.reset('login');
      this.errors$.reset('default');
    },
    restoreAccount() {
      this.setDialogRestoreAccountLoading(true);
      this.errors$.reset('default');
      apiAuthentication
        .restoration(this.access, this)
        .then((res) => {
          this.removeLastCloseDialogFn();
          // Define the user
          this.defineUser(res);
          this.closeRestoreAccount();
        })
        .catch(() => {
          this.errors$.handleError(true, 'default', 'form', null);
        })
        .finally(() => {
          this.setDialogRestoreAccountLoading(false);
        });
    },
    confirmDialogPermissions() {
      const cookieName = `push_denied_${this.getUser.hash}`;
      setCookie(cookieName, true, 30, process.env.VUE_APP_HTTPS === 1);
    },
    dismissDialogPermissions() {
      this.setDialogPermissions(null);
    },
    closeDialogPushInformation() {
      const cookieName = `push_dialog_${this.getUser.hash}`;
      setCookie(cookieName, true, 30, process.env.VUE_APP_HTTPS === 1);
    },
    confirmDialogPushInformation() {
      setCookie(`push_dialog_${this.getUser.hash}`, 'reset', -1, false);
      setCookie(`push_denied_${this.getUser.hash}`, 'reset', -1, false);
      if (!('Notification' in window)) {
        // Ce navigateur ne prend pas en charge la notification de bureau
      } else if (Notification.permission === 'denied') {
        this.setDialogPermissions('push');
      } else {
        this.askPushPermission(true);
      }
    },
    dismissDialogPushInformation() {
      this.setDialogPushInformation(null);
    },
    resetError401() {
      this.errors$.handleError(false, 'http', 401, null);
    },
    resetError500() {
      this.errors$.handleError(false, 'http', 500, null);
    },
    resetError403() {
      this.errors$.handleError(false, 'http', 403, null);
    },
    resetErrorToken() {
      this.errors$.reset('token');
    },
  },
};
