//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'SSsrLink',
  props: {
    name: {
      type: String,
      default: null,
    },
    path: {
      type: String,
      default: null,
    },
    params: {
      type: Object,
      default: () => {},
    },
    query: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    routeData() {
      return {
        ...this.$router.resolve({
          name: this.name,
          path: this.path,
          params: this.params,
          query: this.query,
        }).resolved,
        matched: null,
      };
    },
    ssrUrl() {
      if (this.routeData.meta.private) {
        return {
          ...this.$router.resolve({
            name: 'Login',
            query: { redirect: this.routeData.fullPath },
          }).resolved,
          matched: null,
        };
      }
      return this.routeData;
    },
  },
};
