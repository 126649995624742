//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapActions } from 'vuex';

export default {
  name: 'NuxtError',
  props: {
    url: {
      type: String,
      default: null,
    },
    error: {
      type: Object,
      default: null,
    },
  },
  created() {
    this.enableToolbar();
    this.setBackArrow(false);
    if (this.url) {
      this.$newRouter.replaceState({}, null, this.url);
    }
  },
  methods: {
    ...mapActions({
      enableToolbar: 'menu/enableToolbar',
      setBackArrow: 'transferData/updateBackArrow',
    }),
  },
};

